import { isPlatformBrowser } from '@angular/common';
import { Inject, inject, Injectable, PLATFORM_ID } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';

import { GoogleTagManagerService } from 'angular-google-tag-manager';
import { filter } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { getStorageObject } from 'src/utils/storage-manager';

export enum GoogleTagEventType {
  Search = 'search',
  Login = 'login'
}

// Origin input
export interface GoogleTagSearchTerm {
  event: GoogleTagEventType.Search;
  searchTerm: string;
}

export interface GoogleTagLogin {
  event: GoogleTagEventType.Login;
  userId: number | undefined;
}
export type GoogleTagEvent = GoogleTagSearchTerm | GoogleTagLogin;
declare let gtag: any;

@Injectable({
  providedIn: 'root'
})
export class TrackingService {
  private gtmService = inject(GoogleTagManagerService);
  gtmId = environment.gtmId;
  constructor(
    // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
    @Inject(PLATFORM_ID) private platformId: object,
    private _router: Router
  ) {
    this._router.events.pipe(filter((event: any) => event instanceof NavigationEnd)).subscribe(() => {
      gtag('js', new Date());
      gtag('config', this.gtmId);
    });
  }

  start(): void {
    this.gtmService.addGtmToDom();
  }

  pushTagEvent(tag: GoogleTagEvent): void {
    if (isPlatformBrowser(this.platformId)) {
      const consent = getStorageObject('consentMode') === 'granted';
      if (consent) {
        this.gtmService.pushTag(tag);
      }
    }
  }
}
