<!-- Logo -->
<div class="center row">
  <img [routerLink]="logoUrl" src="/assets/images/logo-simple-light.svg" alt="Movenbus Logo" class="customer-logo" />
</div>

<!-- Links -->
<div class="links">
  <!-- Contact -->
  @if (!isAdminRoute) {
    <a
      [routerLink]="'/contact'"
      [queryParams]="redirectToContactParams()"
      target="_blank"
      class="text-btn no-padding white-color links__link"
    >
      {{ 'Footer.Contact' | translate }}
    </a>
    <p class="dot-separator">·</p>
  }

  <!-- FAQs -->
  <a routerLink="/faqs" target="_blank" class="text-btn no-padding white-color links__link">
    {{ 'Footer.Faq' | translate }}
  </a>
  <p class="dot-separator">·</p>

  <!-- Legal notice -->
  <a routerLink="/legal-notice" target="_blank" class="text-btn no-padding white-color links__link">
    {{ 'Footer.Legal' | translate }}
  </a>
  <p class="dot-separator">·</p>

  <!-- Privacy policy -->
  <a routerLink="/privacy" target="_blank" class="text-btn no-padding white-color links__link">
    {{ 'Footer.Privacy' | translate }}
  </a>
  <p class="dot-separator">·</p>

  <!-- Cookies policy -->
  <a routerLink="/cookies" target="_blank" class="text-btn no-padding white-color links__link">
    {{ 'Footer.Cookies' | translate }}
  </a>
  <p class="dot-separator">·</p>

  <!-- Terms and conditions -->
  <a [routerLink]="termsAndConditionsUrl" target="_blank" class="text-btn no-padding white-color links__link">
    {{ 'Footer.Terms' | translate }}
  </a>
  <p class="dot-separator">·</p>

  <!-- Copyright -->
  <p class="no-margin white-color">Copyright © {{ currentYear }} · Movenbus</p>
</div>
