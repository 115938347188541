<div class="column gap">
  @if (role === roleEnum.OPERATOR) {
    <div class="column center gap-lg">
      <img src="/assets/images/logo-simple-dark.svg" alt="Movenbus Logo" />
      <h1 class="title hide-sm">{{ 'LoginComponent.LoginAsOperator' | translate }}</h1>
      <div>
        <h1 class="display-sm no-margin horizontal-center">{{ 'LoginComponent.MobileLoginTitle' | translate }}</h1>
        <span class="display-sm bold grey-color-500 horizontal-center">{{
          'LoginComponent.MobileLoginSubtitle' | translate
        }}</span>
      </div>
    </div>
  }
  @if (loginError) {
    <div class="login-disclaimer" data-cy="wrong-login-disclaimer">
      <ion-icon class="login-disclaimer__icon icon-md" name="alert-circle-outline"></ion-icon>
      <span class="login-disclaimer__text">{{ invalidLoginMessage | translate }}</span>
    </div>
  }
  <form novalidate class="form-container vertical-center" [formGroup]="loginForm">
    <div class="form-wrapper form__input form-xxxl fill">
      <mat-label>{{ 'CommonTranslations.Email' | translate }}</mat-label>
      <mat-form-field>
        <input
          matInput
          placeholder="{{ 'LoginComponent.PlaceholderEmail' | translate }}"
          type="email"
          formControlName="email"
          name="email"
          required
          email
          data-cy="login-input-email"
        />
        @if (loginForm.get('email')?.hasError('email')) {
          <mat-error>
            {{ 'CommonTranslations.InvalidEmail' | translate }}
          </mat-error>
        }
        @if (loginForm.get('email')?.hasError('required')) {
          <mat-error>
            {{ 'LoginComponent.RequiredErrorEmail' | translate }}
          </mat-error>
        }
      </mat-form-field>
    </div>

    <div class="form-wrapper form__input form-xxxl fill">
      <mat-label>{{ 'CommonTranslations.Password' | translate }}</mat-label>
      <mat-form-field>
        <input
          matInput
          [type]="showPassword ? 'text' : 'password'"
          placeholder="{{ 'LoginComponent.PlaceholderPassword' | translate }}"
          type="password"
          formControlName="password"
          name="password"
          required
          data-cy="login-input-password"
        />
        <mat-icon matSuffix class="padding-xs grey-color-400 icon-md" (click)="togglePasswordVisibility($event)">
          {{ showPassword ? 'visibility_off' : 'visibility' }}
        </mat-icon>
      </mat-form-field>
    </div>
    @if (role === 'OPERATOR') {
      <a
        class="text-btn text-btn--sm text-btn--danger forgot-password"
        routerLink="/operator/forgot-password"
        data-cy="forgot-password-button"
      >
        {{ 'LoginComponent.ForgotPassword' | translate }}
      </a>
    }
    <button
      (click)="login()"
      type="submit"
      mat-button
      class="btn btn--primary btn--lg-mobile fill-xs"
      [disabled]="loginForm.invalid"
      data-cy="login-button"
    >
      <ion-icon name="log-in-outline"></ion-icon>
      {{ 'CommonTranslations.Login' | translate }}
    </button>

    @if (role === roleEnum.OPERATOR) {
      <hr class="divider" />
    }
  </form>

  @if (role === roleEnum.OPERATOR) {
    <div class="column vertical-center">
      <p class="no-margin">{{ 'LoginComponent.RegisterClaim' | translate }}</p>
      <a href="operator/signup" class="text-btn text-btn--sm">
        {{ 'LoginComponent.RegisterClient' | translate }}
      </a>
    </div>
  }
</div>
